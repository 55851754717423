export const handleCommandAction = ({
    command,
    setDataSearch,
    navigate,
    stopListening,
    handleCloseModal,
    handleOpenModal,
    resetTranscript,
    setIsListening,
    setHistory,
    handleSpeechResponse,
    fetchGoogleSearchResults,
    setIsSpeaking
}) => {
    let responseText = '';
    const lowerCommand = command.toLowerCase();
    if (lowerCommand.startsWith('search for') || lowerCommand.startsWith('find news about')) {
        const searchQuery = lowerCommand.replace(/^(search for|find news about)\s/i, '').trim();
        responseText = `Searching for news about "${searchQuery}"`;
        setDataSearch(searchQuery);
        setTimeout(() => {
            navigate(`/search/${encodeURIComponent(searchQuery)}`);
        }, 1000);
        stopListening(true);
        handleCloseModal();
    } else {
        switch (lowerCommand) {
            case 'hello':
            case 'hi':
            case 'hi Persona':
                handleOpenModal();
                responseText = 'How can I help you?';
                break;
            case 'hello hello':
                handleOpenModal();
                responseText = 'How can I help you?';
                break;

            case 'how are you':
                responseText = 'I am fine,I think you will be also fine';
                break;

            case 'okay':
                responseText = 'Perfect ';
                break;

            case 'show me the sports news':
            case 'show the sports news':
            case 'show me sports news':
            case 'show me the sports news':
            case 'get me to sports news':
            case 'go to sports news':
            case 'go to the sports news':
            case 'go to sports':
            case 'go to the sports':
            case 'sports news':
                responseText = 'Here you can see the sports news';
                setTimeout(() => {
                    navigate('/sports');
                }, 1000);
                stopListening(true);
                handleCloseModal();
                break;

            case 'show me the politics news':
            case 'show the politics news':
            case 'show me politics news':
            case 'show me the politics news':
            case 'get me to politics news':
            case 'go to politics news':
            case 'go to the politics news':
            case 'go to politics':
            case 'go to the politics':
            case 'politics news':
                responseText = 'Here you can see the Politics news';
                setTimeout(() => {
                    navigate(`/politics`);
                }, 1000);
                stopListening(true);
                handleCloseModal();
                break;

            case 'show me the entertainment news':
            case 'show the entertainment news':
            case 'show me entertainment news':
            case 'show me the entertainment news':
            case 'get me to entertainment news':
            case 'go to entertainment news':
            case 'go to the entertainment news':
            case 'go to entertainment':
            case 'go to the entertainment':
            case 'entertainment news':
                responseText = 'Here you can see the Entertainment news';
                setTimeout(() => {
                    navigate(`/entertainment`);
                    // window.location.href = 'https://streamdeck.silocloud.io/'
                }, 1000);
                stopListening(true);
                handleCloseModal();
                break;

            case 'search news':
            case 'search for news':
                responseText = 'What topic would you like to search for?';
                setIsListening(true);
                break;

            case 'go to homepage':
            case 'go to the homepage':
            case 'homepage':
            case 'home page':
            case 'go back to home page':
            case 'go back to homepage':
            case 'go back to the homepage':
                responseText = 'moving To homepage';
                setTimeout(() => {
                    navigate('/');
                }, 1000);
                stopListening(true);
                handleCloseModal();
                break;

            case 'stop listening':
            case 'stop':
            case 'exit':
            case 'close':
                handleCloseModal();
                resetTranscript();
                stopListening(true);
                responseText = 'Goodbye!';
                break;
            default:
                responseText = 'Here are the search results';
                fetchGoogleSearchResults(command);
                setIsSpeaking(false);
                break;
        }
    }
    const newEntry = {
        transcript: command,
        responseText: responseText,
        searchResults: [],
        timestamp: new Date().toLocaleString(),
    };
    if (responseText === 'Here are Some best Results') {
        fetchGoogleSearchResults(command).then(results => {
            newEntry.searchResults = results;
            setHistory(prevHistory => [...prevHistory, newEntry]);
            handleSpeechResponse(responseText);
        });
    } else {
        setHistory(prevHistory => [...prevHistory, newEntry]);
        handleSpeechResponse(responseText);
    }
};
