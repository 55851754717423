import React from "react";
import bh from "../images/bh.png";
import "./Blog.css";
import NavBar from "./Navbar";

export default function Bloghead({ imageUrl }) {
  // const backgroundImageUrl = props.imageUrl || "";
  return (
    <>
      <NavBar />
      <div className="container-fluid px-0">
        <div
          className="d-flex my4 mx-auto p-0 bloghead"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.251), rgba(0, 0, 0, 0.256)), url(${imageUrl})`,
            backgroundSize: "cover",
            height: "198px", // Set desired height
          }}
        >
          {/* container  */}
          <h2 className="text-center m-auto text-decoration-underline fw-bold text-light ">Blogs</h2>
          <div className="blogheadimg"></div>
          {/* <img src="{bh}" alt="blogheadimg" className="img blogheadimg" /> */}
        </div>
      </div>
    </>
  );
}
